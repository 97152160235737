@import 'Styles/includes';

.CardNewsList {
    $root: &;

    display: flex;
    flex-direction: column;

    &--Hidden {
        display: none;
    }

    &__FilterWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media(md) {
            margin-bottom: 16px;
            flex-direction: row;
        }
    }

    &__SelectWrapper {
        width: 100%;
        margin-bottom: 16px;
        position: relative;

        @include media(md) {
            width: auto;
            margin-left: 14px;
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 13px;
            left: 12px;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;

            @include media(md) {
                left: 12px;
            }
        }

        &--Place {
            &::before {
                background-image: none;
            }
        }

        &--Category {
            &::before {
                background-image: url('#{$publicpath}img/icon-subject.svg');
            }
        }

        &--Month {
            &::before {
                background-image: url('#{$publicpath}img/icon-clock.svg');
            }
        }

        &--Year {
            &::before {
                background-image: url('#{$publicpath}img/icon-clock.svg');
            }
        }
    }

    &__NoHits {
        text-align: center;
        margin-bottom: 24px;
    }

    &__Select {
        height: 44px;
        padding-bottom: 2px;
        font-size: 1.4rem;
        font-weight: bold;
        border: solid 1px $gray-40;
        border-radius: 4px;
        padding-left: 32px;
        background: transparent;
        appearance: none;
        background-image: url('#{$publicpath}img/icon-arrow-down.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: right 12px center;
        width: 100%;
        color: black;

        &::-ms-expand {
            display: none;
        }

        @include media(md) {
            width: 168px;
        }

        &--Year {
            @include media(md) {
                width: 104px;
            }

        }
        &--Month {
            @include media(md) {
                width: 144px;
            }
        }
    }

    &__CardsWrapper {
        #{$root}--Vertical & {
            display: flex;
            flex-flow: row wrap;
            margin-left: -24px;
        }
    }

    &__Loader {
        display: flex;
        justify-content: center;
    }

    &__Card {
        margin: 0 auto 24px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(black, 0.15);
        background-color: white;
        transition: box-shadow 0.15s ease;

        #{$root}--Vertical & {
            width: calc(100% / 3 - 24px);
            margin: 0 0 33px 24px;

            @include media-max(md) {
                width: 100%;
            }
        }

        &:hover {
            box-shadow: 0 0 6px 0 rgba(black, 0.3);

            @media (hover: none) {
                box-shadow: 0 0 4px 0 rgba(black, 0.15);
            }
        }
    }

    &__GoToPage {
        display: inline-block;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.4px;
        color: $text;
        text-decoration-line: none;
        padding-right: 22px;
        background: transparent url('#{$publicpath}img/icon-arrow-thin.svg')
            no-repeat;
        background-position: right center;
        background-size: 14px;
    }
}
